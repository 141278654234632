// Libraries
import React from 'react';
import { Link } from 'gatsby';

// Styles
import * as styles from '../components/links.module.css';

// Components
import Seo from '../components/seo';
import Layout from '../components/layout';
import Hero from '../components/hero';

const LINKS = [
  {
    to: 'https://matts-citadel.notion.site/Hobby-Wishlist-f69d6b492b4441818252792a1205486b',
    text: 'Wishlists',
    subtitle: 'This links to my notion wishlists',
  },
];

const Links = (props) => {
  return (
    <Layout location={props.location}>
      <Seo title="Links" />
      <Hero title="Links" content="Links that are useful or interesting..." />
      <ul className={styles.linksContainer}>
        {LINKS.map(({ to, text, subtitle }) => (
          <li key={to} className={styles.link}>
            <Link className={styles.linkText} to={to}>
              {text}
            </Link>
            <p>{subtitle}</p>
          </li>
        ))}
      </ul>
    </Layout>
  );
};

export default Links;
